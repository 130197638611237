import React from "react";
import { Link } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";
import { AudioVisualizer } from "../parts/AudioVisualizer/AudioVisualizer";

import * as pageStyles from "../global-styles/page.module.css";

export const About = (props: PageComponentProps) => {
  const { clairnoteName, urlDir, otherClairnoteName, sn } = props;
  const title = "About";
  const description = `Learn all about ${clairnoteName} music notation. See and hear scales, intervals, modes, etc.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <p>
          Learn all about {clairnoteName} and how it makes music easier to read
          and learn. Start with this interactive "AudioVisualizer" which lets
          you see and hear the notes that you play on an on-screen keyboard (or
          a{" "}
          <a
            title="About 6-6 Janko-style Keyboard Layout"
            href="http://musicnotation.org/wiki/instruments/janko-keyboard"
            target="_blank"
          >
            Janko-style keyboard
          </a>
          , guitar, violin, or mandolin).
        </p>

        <AudioVisualizer sn={sn} inputMode="keyboard-trad" />

        <p>
          In addition to the notes you play, you can also see and hear scales,
          intervals, and modes. For more discussion and illustrations check out
          the <Link to={urlDir + "staff/"}>Staff</Link>,{" "}
          <Link to={urlDir + "scales/"}>Scales</Link>, and{" "}
          <Link to={urlDir + "intervals/"}>Intervals</Link> pages.
        </p>
        <p>
          (Note that {clairnoteName}'s <Link to={urlDir + "clefs/"}>clefs</Link>
          , <Link to={urlDir + "key-signatures/"}>key signatures</Link>, and{" "}
          <Link to={urlDir + "accidental-signs/"}>accidental signs</Link> are
          not shown above. When these elements are included {clairnoteName}{" "}
          conveys all of the same information conveyed by traditional notation.)
        </p>
        <p>
          To learn more about {clairnoteName}, begin with the staff and then
          explore how {clairnoteName} represents common musical patterns:
        </p>
        <ul>
          <li>
            <Link to={urlDir + "staff/"}>Staff</Link>
          </li>
          <li>
            <Link to={urlDir + "scales/"}>Scales</Link>
          </li>
          <li>
            <Link to={urlDir + "intervals/"}>Intervals</Link>
          </li>
          <li>
            <Link to={urlDir + "chords/"}>Chords</Link>
          </li>
        </ul>
        <p>
          Next learn about all the various aspects of {clairnoteName} as an
          alternative music notation system:
        </p>
        <ul>
          <li>
            <Link to={urlDir + "clefs/"}>Clefs</Link>
          </li>
          <li>
            <Link to={urlDir + "key-signatures"}>Key Signatures</Link>
          </li>
          <li>
            <Link to={urlDir + "accidental-signs/"}>Accidental Signs</Link>
          </li>
          <li>
            <Link to={urlDir + "names-of-notes-and-intervals/"}>
              Names of Notes and Intervals
            </Link>
          </li>
          <li>
            <Link to={urlDir + "rhythm-notation/"}>Rhythm Notation</Link>
          </li>
          <li>
            <Link to={urlDir + "faq/"}>FAQ</Link>
          </li>
        </ul>
        <p>
          Check out {otherClairnoteName}, a notation system that's similar to{" "}
          {clairnoteName}:
        </p>
        <ul>
          <li>
            <Link to={urlDir + "clairnote-dn-clairnote-sn/"}>
              {clairnoteName} and {otherClairnoteName}
            </Link>
          </li>
        </ul>
      </article>
    </Layout>
  );
};
