import { PageProps } from "gatsby";
import React from "react";

import { About } from "../../components/pages/About";

import { dnProps } from "../../js/utils";

const AboutPage = (props: PageProps) => <About {...{ ...dnProps, ...props }} />;

export default AboutPage;
